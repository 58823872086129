import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/Portfolio.module.css";

const PortfolioItem = ({
  image,
  title,
  description,
  link,
  tags,
  limit = 155,
}: any) => {
  const { t } = useTranslation();
  image ??= window.location.origin + "/img/empty_project.png";
  const isGoogle = link.includes("play.google");
  const isApple = link.includes("apple");
  const isItch = link.includes("itch");
  return (
    <div className={styles.item}>
      <img className={styles.image} src={image} alt="game" />
      <div className={styles.itemText}>
        <p className={styles.title}>{title}</p>
        <p className={styles.description}>
          {description.slice(0, limit)}
          {description.length > limit ? ".." : ""}
        </p>
        {tags && (
          <div className={styles.tagsWrap}>
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="1.5"
                cy="1.5"
                r="1.5"
                transform="matrix(1 0 0 -1 15 7.00024)"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1.77423 10.1439C0.771085 11.2643 0.749503 12.9546 1.67016 14.1437C3.49711 16.5033 5.49674 18.5029 7.85633 20.3298C9.0454 21.2505 10.7357 21.2289 11.8561 20.2258C14.8979 17.5022 17.6835 14.6559 20.3719 11.5279C20.6377 11.2187 20.8039 10.8397 20.8412 10.4336C21.0062 8.63798 21.3452 3.46467 19.9403 2.05974C18.5353 0.654811 13.362 0.993766 11.5664 1.15876C11.1603 1.19608 10.7813 1.36233 10.472 1.62811C7.34412 4.31646 4.49781 7.10211 1.77423 10.1439Z"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M6 13.0002L9 16.0002"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <b>{tags}</b>
          </div>
        )}
        <div className={styles.badges}>
          {!isGoogle && !isApple && !isItch && (
            <a
              href={link}
              target="_blank"
              rel="noreferrer"
              className={styles.customBadge}
            >
              {t("Play")}
            </a>
          )}
          {(isGoogle || isApple || isItch) && (
            <>
              {isGoogle && (
                <a href={link} target="_blank" rel="noreferrer">
                  <img
                    className={styles.badge}
                    src={
                      window.location.origin +
                      "/img/badges/google_play_badge.svg"
                    }
                    alt="google"
                  />
                </a>
              )}
              {isApple && (
                <a href={link} target="_blank" rel="noreferrer">
                  <img
                    className={styles.badge}
                    src={
                      window.location.origin + "/img/badges/app_store_badge.svg"
                    }
                    alt="apple"
                  />
                </a>
              )}
              {isItch && (
                <a href={link} target="_blank" rel="noreferrer">
                  <img
                    className={styles.badge}
                    src={window.location.origin + "/img/badges/itch.svg"}
                    alt="itch"
                  />
                </a>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export const Portfolio = () => {
  const { t } = useTranslation();
  const [showAll, setShowAll] = React.useState<boolean>(false);

  const handleClick = () => {
    setShowAll((prev: boolean) => !prev);
  };

  return (
    <div className={styles.wrapper}>
      {showAll ? (
        <>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/AvsU.png"}
              title="Archer vs Undead"
              description={t("AvsU_description")}
              link="https://play.google.com/store/apps/details?id=org.octo.archer.undead.tower.defense"
              tags="2D, Simulator, Survival, Defense"
            />
            <PortfolioItem
              image={
                window.location.origin + "/img/portfolio/jigsaw_puzzle.png"
              }
              title="Jigsaw Craze Puzzles"
              description={t("Jigsaw_description")}
              link="https://play.google.com/store/apps/details?id=com.Prowebwork.JigsawCraze"
              tags="2D, Puzzle"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/FreightRun.png"}
              title="Neon Race"
              description={t("FreightRun_Description")}
              link="https://wait-what-interactive.itch.io/neon-race"
              tags="2D, Arcade, Cyberpunk, Web3, Action, Racing"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/Mafia.png"}
              title="Mafia Defense"
              description={t("MafiaDefense_description")}
              link="https://play.google.com/store/apps/details?id=org.octo.mafia.tower.defense"
              tags="2D, Simulator, Survival, Defense"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/eternal-rush.png"}
              title="Eternal Rush"
              description={t("EternalRush_Description")}
              link="https://play.google.com/store/apps/details?id=org.octo.eternal.rush.rpg"
              tags="2D, Pixel-Art, Runner, Role Play, Idle"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/rpg.png"}
              title="Knight's Rampage"
              description={t("RPG_description")}
              link="https://play.google.com/store/apps/details?id=com.SiverGames.RPG_Knights_Rampage"
              tags="2D, Role Play, Action"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={
                window.location.origin + "/img/portfolio/radiant_defender.png"
              }
              title="Radiant Defender"
              description={t("RadiantDefender_description")}
              link="https://radiant-arcade.com/play/radiant-defender"
              tags="2D, Role Play, BulletHell, Pixel-Art, Web3, Survival"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/LKA.png"}
              title="Little Knight Adventure"
              description={t("LittleKnightAdventure")}
              link="https://play.google.com/store/apps/details?id=org.knight.run"
              tags="2D, Role Play, Runner, Idle"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/DeLibertate.png"}
              title="De Libertate"
              description={t("DeLibertate_description")}
              link="https://play.google.com/store/apps/details?id=com.rexevropae.DeLibertateUkraine19171922"
              tags="2D, Strategy, Turn-Based, Pixel-Art"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/SWTR.png"}
              title="Smart Ways To Root"
              description={t("SWTR_description")}
              link="https://madisan.itch.io/smart-ways-to-root"
              tags="2D, Casual, Point & Click, Puzzle"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/IntoTheRoots.png"}
              title="Into The Roots"
              description={t("IntoTheRoots_description")}
              link="https://paveleon.itch.io/into-the-roots"
              tags="2D, Casual, Arcade, Puzzle"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/color_wars.png"}
              title="Color Wars. Towers!"
              description={t("ColorWars_description")}
              link="https://wait-what-interactive.itch.io/color-wars-towers"
              tags="2D, Puzzle, Tower Defense, Paper, Realistic"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/hunters_rite.png"}
              title="Hunters Rite"
              description={t("HuntersRite_description")}
              link="https://fugas-education.itch.io/hunters-rite"
              tags="2D, Pixel-Art, Role Play, Local Coop, RogueLike"
              limit={370}
            />
          </div>
        </>
      ) : (
        <>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/AvsU.png"}
              title="Archer vs Undead"
              description={t("AvsU_description")}
              link="https://play.google.com/store/apps/details?id=org.octo.archer.undead.tower.defense"
              tags="2D, Simulator, Survival, Defense"
            />
            <PortfolioItem
              image={
                window.location.origin + "/img/portfolio/jigsaw_puzzle.png"
              }
              title="Jigsaw Craze Puzzles"
              description={t("Jigsaw_description")}
              link="https://play.google.com/store/apps/details?id=com.Prowebwork.JigsawCraze"
              tags="2D, Puzzle"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/FreightRun.png"}
              title="Neon Race"
              description={t("FreightRun_Description")}
              link="https://wait-what-interactive.itch.io/neon-race"
              tags="2D, Arcade, Cyberpunk, Web3, Action, Racing"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/Mafia.png"}
              title="Mafia Defense"
              description={t("MafiaDefense_description")}
              link="https://play.google.com/store/apps/details?id=org.octo.mafia.tower.defense"
              tags="2D, Simulator, Survival, Defense"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/eternal-rush.png"}
              title="Eternal Rush"
              description={t("EternalRush_Description")}
              link="https://play.google.com/store/apps/details?id=org.octo.eternal.rush.rpg"
              tags="2D, Pixel-Art, Runner, Role Play, Idle"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/rpg.png"}
              title="Knight's Rampage"
              description={t("RPG_description")}
              link="https://play.google.com/store/apps/details?id=com.SiverGames.RPG_Knights_Rampage"
              tags="2D, Role Play, Action"
            />
          </div>
          <div className={styles.itemsRow}>
            <PortfolioItem
              image={
                window.location.origin + "/img/portfolio/radiant_defender.png"
              }
              title="Radiant Defender"
              description={t("RadiantDefender_description")}
              link="https://radiant-arcade.com/play/radiant-defender"
              tags="2D, Role Play, BulletHell, Pixel-Art, Web3, Survival"
            />
            <PortfolioItem
              image={window.location.origin + "/img/portfolio/LKA.png"}
              title="Little Knight Adventure"
              description={t("LittleKnightAdventure")}
              link="https://play.google.com/store/apps/details?id=org.knight.run"
              tags="2D, Role Play, Runner, Idle"
            />
          </div>
        </>
      )}
      <button className={styles.mainButton} onClick={handleClick}>
        {!showAll ? t("All Games") : t("Less")}
      </button>
    </div>
  );
};
