import React from "react";

import styles from "../../assets/css/Partners.module.css";

export const Partners = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.item + " " + styles.RIT}>
        <a href="https://www.rv-it.college/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/ITCollegeEN.png"}
            alt="Rivne IT College"
          />
        </a>
      </div>
      <div className={styles.item + " " + styles.PFL}>
        <a href="https://playfusionlabs.com/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/playfusionlabs.svg"}
            alt="Play Fusion Labs"
          />
        </a>
      </div>
      <div className={styles.item + " " + styles.AU}>
        <a href="https://art-unchained.io/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/art_unchained.svg"}
            alt="Art Unchained"
          />
        </a>
      </div>
      <div className={styles.item}>
        <a href="https://nfttallinn.com/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/NFT_Tallinn.png"}
            alt="NFT Tallinn"
          />
        </a>
      </div>
      <div className={styles.item}>
        {/* <a href="https://worldoffreight.xyz/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/logo.svg"}
            alt="WOF"
          />
        </a> */}
        <a href="https://woflabs.com/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/wof_labs.svg"}
            alt="WOF Labs"
          />
        </a>
      </div>
      <div className={styles.item + " " + styles.FUGAS}>
        <a href="https://fugas.space/" target="_blank" rel="noreferrer">
          <img
            src={window.location.origin + "/img/partners/fugas_logo.png"}
            alt="FUGAS"
          />
        </a>
      </div>
    </div>
  );
};
