import React from "react";

import styles from "../../assets/css/HorizontalScroll.module.css";

export const HorizontalScroll = () => {
  const [progress, setProgress] = React.useState<number>(0);

  React.useEffect(() => {
    const onScroll = () => {
      const newProgress: any =
        window.scrollY / (document.body.scrollHeight - window.outerHeight);
      if (newProgress === progress) return;

      setProgress(newProgress);
    };

    onScroll();

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [document.body.scrollHeight]);

  return (
    <div className={styles.wrapper}>
      <div
        className={styles.progress}
        style={{ width: `${progress * 100}%` }}
      ></div>
    </div>
  );
};
