import { IconButton } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/Header.module.css";
import { HorizontalScroll } from "./HorizontalScroll";
import { LocaleDropDown } from "./LocaleDropDown";

export const Header = () => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = React.useState<boolean>(false);

  const disableScroll = () => {
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))
      document.body.style.overflowY = "hidden";
  };

  const enableScroll = () => (document.body.style.overflowY = "auto");

  const handleClick = (e: any) => {
    if (openMenu) enableScroll();
    else disableScroll();

    setOpenMenu((prev: boolean) => !prev);
  };

  const clickFullMenuLink = (e: any) => {
    enableScroll();
    setOpenMenu(false);
  };

  return (
    <div className={styles.container}>
      <HorizontalScroll />
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <a href="#top" className={styles.mainLink}>
            <div className={styles.logoImage}>
              <p>
                <p className={styles.biggerLogoText}>WAIT.</p>
                <br />
                WHAT.
              </p>
            </div>
          </a>
          <div className={styles.linksWrap}>
            <div className={styles.linksList}>
              <a href="#top" className={styles.link}>
                {t("Main")}
              </a>
              <a href="#about" className={styles.link}>
                {t("About us")}
              </a>
              <a href="#portfolio" className={styles.link}>
                {t("Portfolio")}
              </a>
              <a href="#team" className={styles.link}>
                {t("Team")}
              </a>
              <a href="#partners" className={styles.link}>
                {t("Partners")}
              </a>
              {/* <ListItem className={styles.link}>Відгуки</ListItem> */}
            </div>
          </div>
          <div className={styles.buttons}>
            <LocaleDropDown />
            <IconButton onClick={handleClick}>
              {openMenu ? (
                <img src={window.location.origin + "/img/burger_close.svg"} />
              ) : (
                <img src={window.location.origin + "/img/burger.svg"} />
              )}
            </IconButton>
          </div>
        </div>
      </div>
      <div
        className={styles.fullMenu}
        style={{ display: openMenu ? "flex" : "none" }}
      >
        <div className={styles.leftColumn}>
          <div className={styles.linksColumn}>
            <a href="#main" className={styles.link} onClick={clickFullMenuLink}>
              {t("Main")}
            </a>
            <a
              href="#about"
              className={styles.link}
              onClick={clickFullMenuLink}
            >
              {t("About us")}
            </a>
            <a
              href="#portfolio"
              className={styles.link}
              onClick={clickFullMenuLink}
            >
              {t("Portfolio")}
            </a>
            <a href="#team" className={styles.link} onClick={clickFullMenuLink}>
              {t("Team")}
            </a>
            <a
              href="#partners"
              className={styles.link}
              onClick={clickFullMenuLink}
            >
              {t("Partners")}
            </a>
            {/* <ListItem className={styles.link}>Відгуки</ListItem> */}
          </div>
        </div>
        <div className={styles.rightColumn}>
          <div className={styles.fullMenuLogo}>
            <img
              src={window.location.origin + "/img/footer_logo.png"}
              alt="footer_logo"
            />
          </div>
          <div className={styles.linksWrapper}>
            <p className={styles.linksTitle}>{t("Join in, Traveler")}</p>
            <div className={styles.linksRow}>
              <div className={styles.socialLink}>
                <a
                  href="https://twitter.com/waitwhatcompany"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin + "/img/social/footer/twitter.svg"
                    }
                    alt="link"
                  />
                </a>
              </div>
              <div className={styles.socialLink}>
                <a
                  href="http://www.tiktok.com/@wait.what.interactive"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin + "/img/social/footer/tiktok.svg"
                    }
                    alt="link"
                  />
                </a>
              </div>
              <div className={styles.socialLink}>
                <a
                  href="https://www.reddit.com/u/WaitWhatInteractive"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin + "/img/social/footer/reddit.svg"
                    }
                    alt="link"
                  />
                </a>
              </div>
              <div className={styles.socialLink}>
                <a
                  href="https://www.instagram.com/wait.what.interactive"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin +
                      "/img/social/footer/instagram.svg"
                    }
                    alt="link"
                  />
                </a>
              </div>
              <div className={styles.socialLink}>
                <a
                  href="https://www.linkedin.com/company/wait-what-interactive/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin + "/img/social/footer/linkedIn.svg"
                    }
                    alt="link"
                  />
                </a>
              </div>
              <div className={styles.socialLink}>
                <a
                  href="https://www.facebook.com/people/Wait-What-Interactive/100089077548655/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={
                      window.location.origin + "/img/social/footer/facebook.svg"
                    }
                    alt="link"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
