import { CircularProgress, ThemeProvider } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { BlockTitle } from "./components/UI/BlockTitle";
import { Footer } from "./components/UI/Footer";
import { Header } from "./components/UI/Header";
import { Main } from "./components/UI/Main";
import { OurProject } from "./components/UI/OurProject";
import { Portfolio } from "./components/UI/Portfolio";
import { Stats } from "./components/UI/Stats";
import { Team } from "./components/UI/Team";
import { lightTheme } from "./theme/themes";

import { useSelector } from "react-redux";
import { RootState } from "./store/store";
import { Partners } from "./components/UI/Partners";
import { LoadingScreen } from "./components/UI/LoadingScreen";

import styles from "./assets/css/LoadingScreen.module.css";

const AppContent = React.memo(() => {
  const { t } = useTranslation();

  return (
    <ThemeProvider theme={{ ...lightTheme }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div id="top"></div>
        <Header />
        <Main />
        <div id="about"></div>
        <Stats />
        <OurProject />
        <div id="portfolio"></div>
        <BlockTitle title={t("Portfolio")} />
        <Portfolio />
        <div id="team"></div>
        <BlockTitle title={t("Team")} />
        <Team />
        <div id="partners"></div>
        <BlockTitle title={t("Partners")} />
        <Partners />
        {/* <BlockTitle title="Відгуки" />
        <BlockTitle title="Зворотній звʼязок" /> */}
        <Footer />
      </div>
    </ThemeProvider>
  );
});

export const App = () => {
  const [siteLoading, setSiteLoading] = React.useState(true);
  const loading = useSelector((store: RootState) => store.locale.loading);

  const hideAfterAnimation = () => {
    setTimeout(() => {
      document.querySelector("." + styles.hide)?.remove();
    }, 600);
  };

  React.useEffect(() => {
    const scrollToHashElement = () => {
      setTimeout(() => {
        const { hash } = window.location;
        const elementToScroll = document.getElementById(hash?.replace("#", ""));

        if (!elementToScroll) return;

        elementToScroll.scrollIntoView({
          block: "start",
          inline: "start",
          behavior: "smooth",
        });
      }, 300);
    };

    scrollToHashElement();
    window.addEventListener("hashchange", scrollToHashElement);
    return window.removeEventListener("hashchange", scrollToHashElement);
  }, [siteLoading]);

  React.useEffect(() => {
    document.body.style.overflowY = "hidden";
    const onPageLoad = () => {
      setTimeout(() => {
        setSiteLoading(false);
        document.body.style.overflowY = "auto";
        hideAfterAnimation();
      }, 900);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      setSiteLoading(false);
      document.body.style.overflowY = "auto";
      hideAfterAnimation();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        position: "relative",
      }}
    >
      {!loading && <AppContent />}
      <LoadingScreen className={loading || siteLoading ? "" : styles.hide} />
    </div>
  );
};
