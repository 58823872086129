import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
      dark: "#CB3750",
      contrastText: "#fff",
    //   light: "#fff",
    },
    secondary: {
      main: "#ffD500",
    },
    // error: {},
    // warning: {},
    // info: {},
    // success: {},
  },
  typography: {
    fontFamily: "'IBM Plex Mono'"
  }
});


export { lightTheme };
