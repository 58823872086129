import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/Footer.module.css";

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.footerLogo}>
            <img
              src={window.location.origin + "/img/footer_logo.png"}
              alt="footer_logo"
            />
          </div>
          <div className={styles.infoPart}>
            <div className={styles.tabsRow}>
              <div className={styles.tabsColumn}>
                <a className={styles.tab} href="#top">
                  {t("Main")}
                </a>
                <a className={styles.tab} href="#about">
                  {t("About us")}
                </a>
              </div>
              <div className={styles.tabsColumn}>
                <a className={styles.tab} href="#portfolio">
                  {t("Portfolio")}
                </a>
                <a className={styles.tab} href="#partners">
                  {t("Partners")}
                </a>
              </div>
              <div className={styles.tabsColumn}>
                <a className={styles.tab} href="#team">
                  {t("Team")}
                </a>
              </div>
            </div>
            <div className={styles.infoRow}>
              <div>
                <p className={styles.linksTitle}>{t("Join in, Traveler")}</p>
                <div className={styles.linksRow}>
                  <div className={styles.link}>
                    <a
                      href="https://twitter.com/waitwhatcompany"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/twitter.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                  <div className={styles.link}>
                    <a
                      href="http://www.tiktok.com/@wait.what.interactive"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/tiktok.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                  <div className={styles.link}>
                    <a
                      href="https://www.reddit.com/u/WaitWhatInteractive"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/reddit.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                  {/* <div className={styles.link}>
                  <a
                    href="https://t.me/WaitWhatInteractive"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={
                        window.location.origin +
                        "/img/social/footer/telegram.svg"
                      }
                      alt="link"
                    />
                  </a>
                </div> */}
                  <div className={styles.link}>
                    <a
                      href="https://www.instagram.com/wait.what.interactive"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/instagram.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                  <div className={styles.link}>
                    <a
                      href="https://www.linkedin.com/company/wait-what-interactive/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/linkedIn.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                  <div className={styles.link}>
                    <a
                      href="https://www.facebook.com/people/Wait-What-Interactive/100089077548655/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/facebook.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div className={styles.contactsWrap}>
                <p className={styles.linksTitle}>{t("Contacts")}</p>
                <div className={styles.linksRow}>
                  <div className={styles.link}>
                    <a
                      href="https://t.me/WaitWhatInteractive"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/telegram.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                  <div className={styles.link}>
                    <a
                      href="mailto:connect.waitwhat@gmail.com"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          window.location.origin +
                          "/img/social/footer/email.svg"
                        }
                        alt="link"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className={styles.copyrightText}>
        © 2023 Wait What Interactive. All rights reserved.
      </p>
    </div>
  );
};
