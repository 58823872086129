import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/Main.module.css";

export const Main = () => {
  const { t } = useTranslation();

  const onPressButton = () => {
    window.location.hash = "";
    window.location.hash = "about";
  }

  return (
    <div className={styles.wrapper} id="main">
      <div className={styles.mainTextWrap}>
        <h1 className={styles.title}>Wait.What.Interactive</h1>
        <h4 className={styles.subtitle}>
          {t(
            "A team of independent developers who create video games based on your ideas."
          )}
          <br />
          {t(
            "Our professional communication is the beginning of productive cooperation"
          )}
        </h4>
        <button onClick={onPressButton} className={styles.mainButton}>{t("Let's start")}</button>
      </div>
      <div className={styles.loadMoreWrap}>
        <p className={styles.loadText}>{t("Scroll down")}</p>
        <a href="#about">
          <img
            src={window.location.origin + "/img/main_arrow.svg"}
            alt="arrow"
          />
        </a>
      </div>
    </div>
  );
};
