import React from "react";

import styles from "../../assets/css/BlockTitle.module.css";

export const BlockTitle = ({ title }: any) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>
        {title}
        <div className={styles.border}></div>
      </p>
    </div>
  );
};
