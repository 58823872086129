import React from "react";

import styles from "../../assets/css/LoadingScreen.module.css";

export const LoadingScreen = (props: any) => {
  return (
    <div className={styles.wrapper + " " + props.className} >
      <img
        src={window.location.origin + "/img/wwi_cat_white_non_back.png"}
        alt="loading_logo"
      />
    </div>
  );
};
