import React from "react";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/OurProject.module.css";

export const OurProject = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.wrapper}>
      <div className={styles.wrap}>
        <img
          src={window.location.origin + "/img/project_announce.png"}
          alt="announce cat"
        />
        <p className={styles.text}>
          {t("Preparing")}
          <br />
          {t("Something interesting")}
        </p>
      </div>
    </div>
  );
};
