import React, { RefObject } from "react";
import { useTranslation } from "react-i18next";
import { useCountUp } from "react-countup";
import parse from "html-react-parser";

import styles from "../../assets/css/Stats.module.css";

const StatLine = ({ index, text }: any) => {
  return (
    <div className={styles.statWrapper}>
      <div className={styles.indexWrapper}>
        <p className={styles.indexText}>{index > 9 ? index : `0${index}`}</p>
      </div>
      <div className={styles.titleWrapper}>
        <p className={styles.titleText}>{text}</p>
      </div>
    </div>
  );
};

const Timer = () => {
  const daySecs = 24 * 3600;
  const hrSecs = 3600;
  const minSecs = 60;

  const calculateText = () => {
    const startDate = new Date(2021, 3, 2, 21, 17, 42);
    const now = new Date();
    let diff = (now.getTime() - startDate.getTime()) / 1000;

    const days = Math.floor(diff / daySecs);
    diff = diff % daySecs;
    const hrs = Math.floor(diff / hrSecs);
    diff = diff % hrSecs;
    const mins = Math.floor(diff / minSecs);
    diff = diff % minSecs;
    diff = Math.floor(diff);

    let totalString = "";
    totalString += days > 0 ? (days > 9 ? `${days}D:` : `0${days}D:`) : "00D:";
    totalString += hrs > 0 ? (hrs > 9 ? `${hrs}H:` : `0${hrs}H:`) : "00H:";
    totalString += mins > 0 ? (mins > 9 ? `${mins}M:` : `0${mins}M:`) : "00M:";
    totalString += diff > 0 ? (diff > 9 ? `${diff}S` : `0${diff}S`) : "00S";
    if (totalString[totalString.length - 1] == ":")
      totalString = totalString.substring(0, totalString.length - 1);

    return totalString;
  };

  const [text, setText] = React.useState(calculateText());

  React.useEffect(() => {
    const interval = setInterval(() => setText(calculateText()), 1000);
    return () => clearInterval(interval);
  }, []);

  return <p className={styles.timerText}>{text}</p>;
};

export default function useOnScreen(ref: any) {
  const [isIntersecting, setIntersecting] = React.useState(false);

  const observer = React.useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  React.useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}

export const Stats = () => {
  const { t } = useTranslation();
  const [animated, setAnimated] = React.useState(false);

  const ref = React.useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);

  const countUpRef1 = React.useRef(null);
  const { start: s1 } = useCountUp({
    ref: countUpRef1,
    start: 0,
    end: 223,
    duration: 4,
    useEasing: true,
  });
  const countUpRef2 = React.useRef(null);
  const { start: s2 } = useCountUp({
    ref: countUpRef2,
    start: 0,
    end: 695,
    duration: 3,
    useEasing: true,
  });
  const countUpRef3 = React.useRef(null);
  const { start: s3 } = useCountUp({
    ref: countUpRef3,
    start: 0,
    end: 47,
    duration: 5,
    useEasing: true,
  });
  const countUpRef4 = React.useRef(null);
  const { start: s4 } = useCountUp({
    ref: countUpRef4,
    start: 0,
    end: 10111,
    duration: 4,
    useEasing: true,
  });

  if (isVisible && !animated) {
    s1();
    s2();
    s3();
    s4();
    setAnimated(true);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.topRow}>
        <div className={styles.leftColumn}>
          <div className={styles.titleTextWrapper}>
            <div className={styles.timerIcon}>
              <img src={window.location.origin + "/img/timer_icon.png"} />
            </div>
            <div className={styles.textBlock}>
              <p className={styles.smallTitle}>{t("Already creating")}</p>
              <Timer />
            </div>
          </div>
          <div>
            <StatLine index={1} text={t("We create unique video games")} />
            <StatLine
              index={2}
              text={t("We develop together with the players")}
            />
            <StatLine
              index={3}
              text={t("We are improving the Ukrainian gaming industry")}
            />
          </div>
        </div>
        <div className={styles.statImage + " " + styles.statImage_1}>
          {/* <img src={window.location.origin + "/img/stats_1.png"} alt="stat_1" /> */}
        </div>
      </div>
      <div className={styles.midRow} ref={ref}>
        <div className={styles.mainStatContainer}>
          <div className={styles.indexWrapper}>
            <p className={styles.indexText}>04</p>
          </div>
          <div className={styles.mainStatWrapper}>
            <div className={styles.mainStatRowWrapper}>
              <div className={styles.mainStatItemWrapper}>
                <p className={styles.mainStatItemTitle}>
                  {parse(t("Completed projects"))}
                </p>
                <p className={styles.mainStatItemValue}>
                  <span ref={countUpRef1} />
                </p>
              </div>
              <div className={styles.mainStatItemWrapper}>
                <p className={styles.mainStatItemTitle}>
                  {parse(t("Our players"))}
                </p>
                <p className={styles.mainStatItemValue}>
                  <span ref={countUpRef2} />k
                </p>
              </div>
            </div>
            <div className={styles.mainStatRowWrapper}>
              <div className={styles.mainStatItemWrapper}>
                <p className={styles.mainStatItemTitle}>
                  {parse(t("Loyal customers"))}
                </p>
                <p className={styles.mainStatItemValue}>
                  <span ref={countUpRef3} />
                </p>
              </div>
              <div className={styles.mainStatItemWrapper}>
                <p className={styles.mainStatItemTitle}>
                  {parse(t("The team is working"))}
                </p>
                <p className={styles.mainStatItemValue}>
                  <span ref={countUpRef4} />h
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.bottomRow}>
        <div className={styles.statImage + " " + styles.statImage_2}>
          {/* <img src={window.location.origin + "/img/stats_2.png"} alt="stat_2" /> */}
        </div>
        <div className={styles.rightColumn}>
          <StatLine
            index={5}
            text={t("Game development and support (WebGL, PC, Android, IOS)")}
          />
          <StatLine index={6} text={t("AR applications")} />
          <StatLine index={7} text={t("Implementation of third-party SDKs")} />
          <StatLine index={8} text={t("Creation of prototypes")} />
          <StatLine index={9} text={t("Creation of mini-games")} />
          <StatLine index={10} text={t("Game design")} />
        </div>
      </div>
    </div>
  );
};
