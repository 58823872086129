import React from "react";
import { MenuItem, Select, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import styles from "../../assets/css/Header.module.css";

interface Locale {
  id: string;
  name: string;
  label: string;
}

export const LocaleDropDown = () => {
  const { t, i18n } = useTranslation();
  const locales: Array<Locale> = [
    { id: "1", name: "en", label: t("US") },
    { id: "2", name: "ua", label: t("UA") },
  ];

  const [locale, setLocale] = React.useState<string>(
    locales?.find((loc) => loc?.name === i18n?.language)?.id ?? ""
  );

  React.useEffect(() => {
    setLocale(locales?.find((loc) => loc?.name === i18n?.language)?.id ?? "");
  }, []);

  React.useEffect(() => {
    setLocale(locales?.find((loc) => loc?.name === i18n?.language)?.id ?? "");
  }, [i18n.language]);

  const onChangeLocale = (event: any) => {
    const newLocale = event.target.value;
    if (newLocale === locale) return;

    setLocale(newLocale);

    const newLocaleName =
      locales.find((loc) => loc.id === newLocale)?.name ?? "";
    i18n.changeLanguage(newLocaleName);

    localStorage.setItem("wwi_landing_locale", newLocaleName);
  };

  return (
    <>
      <Select
        className={styles.select}
        renderValue={() => {
          const current = locales.find((loc) => loc?.id === locale);
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="language-flag"
                src={
                  window.location.origin +
                  `/img/flags/${current?.label ?? ""}.svg`
                }
                alt=""
              />
              <Typography className="language-link">
                {current?.label ?? ""}
              </Typography>
            </div>
          );
        }}
        value={locale}
        onChange={onChangeLocale}
      >
        {locales.map((locale) => (
          <MenuItem key={locale.id.toString()} value={locale.id}>
            <img
              src={window.location.origin + `/img/flags/${locale.label}.svg`}
              className="language-flag"
              alt=""
            />{" "}
            <span className="language-link">{locale.label}</span>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
